import React, {useState} from 'react';
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useParams} from 'react-router-dom'

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IMGRed from '../../assets/logo-n.png'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AlertText from '../../components/Alert/alert';
import { useNavigate } from "react-router-dom";
import { api } from '../../services/api';


const schema = yup.object({
    password: yup.string().min(6, "A senha deve conter no mínimo 6 caracteres").required("a Senha é obrigatória"),
    password2: yup.string().min(6, "A senha deve conter no mínimo 6 caracteres").required("a Senha é obrigatória").oneOf([yup.ref('password')], 'Senhas devem ser iguais !'),
  }).required();


const ConfirmResetPassword = () => {
    const { control , handleSubmit, watch, formState: { errors }} = useForm({
        resolver: yupResolver(schema)
      })
    const [alert, setAlert] = useState({ open: false, texto: '', tipoalert: 'warning'})
    const { token } = useParams()
    const [ blockbutton, setBlockButton] = useState(false)
    const navigate = useNavigate()

    const AtualizarSenha = (texto) => {
        const senha = texto.password
        setBlockButton(true)
        api.post(`/auth/reset_password/confirm/`, { 'password': senha, 'token': token})
        .then((res) => {
            setAlert({open: true, texto: 'Alterado com sucesso !', tipoalert: 'success'})
            navigate('/')
        })
        .catch((err) => {
            setAlert({open: true, texto: 'Algo deu errado !!', tipoalert: 'warning'})
        })
        .finally((x) => {
            setBlockButton(false)
        })
    }

    return (
        <div className='container-login'>
            <div className='caixa-login'>
                <img src={IMGRed} onClick={() => navigate('/')}/>
                <Box id="box-login" autoComplete="off">
                <h2>Resetar senha</h2>
                <AlertText data={alert} close={() => setAlert({...alert, open: !alert.open })} />
                    <form className="formulario">
                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField id="login-senha" label="Senha" variant="outlined" fullWidth onChange={onChange} value={value} type="password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKeyIcon />
                                            </InputAdornment>
                                        ),
                                        }}/>                                                                
                                )}
                                />
                                <br/>
                                {errors.password ? <span>{errors.password.message}</span> : null}
                                <Controller
                                name="password2"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField id="login-senha" label="Confirme sua Senha" variant="outlined" fullWidth onChange={onChange} value={value} type="password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKeyIcon />
                                            </InputAdornment>
                                        ),
                                        }}/>                                                                
                                )}
                                />
                                {errors.password2 ? <span>{errors.password2.message}</span> : null}
                                <br/>
                            <div className="buttons-login">
                            <Button disabled={blockbutton} variant="contained" onClick={handleSubmit((texto) => AtualizarSenha(texto))}>Atualizar Senha</Button>
                            </div>
                        </form>
                </Box>
            </div>
        </div>
    )
}

export default ConfirmResetPassword;