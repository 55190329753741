import React, {useState, useRef} from 'react'
import { Modal, Box, Button } from '@mui/material'
import './editprofile.css'
import Avatar from 'react-avatar-edit'



const EditImgPerfil = ({open , close , img}) => {
    const [state, setState] = useState({
        preview: null,
    })
    const [previewimg, setPreviewImg] = useState(null)


    console.log(previewimg)
    return (
        <Modal
        open={open}
        onClose={() => close(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box id='box-modal-profile-edit'      
            component="form"
            sx={{
                '& .MuiTextField-root': { m: '1rem 0 0 0', width: '100%' },
            }}
            noValidate
            autoComplete="off">
                <Avatar
                    width={250}
                    height={250}
                    onCrop={preview =>setState({...state, preview: preview})}

                    />  


                <Button>Salvar</Button>
            </Box>
        </Modal>
    )
}

export default EditImgPerfil;