import React, { useState, useContext } from 'react';
import DadosUser from '../index/components/dadosuser/dadosuser';
import News from '../index/components/news/news';
import './profile.css'
import NavBar from '../../components/NavBar/navbar';
import { AuthContext } from '../../contexts/auth';
import { Avatar, Button } from '@mui/material';
import { api } from '../../services/api'
import { IconButton } from '@mui/material'
import Badge from '@mui/material/Badge';
import WorkIcon from '@mui/icons-material/Work';
import HouseIcon from '@mui/icons-material/House';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SchoolIcon from '@mui/icons-material/School';
import EditProfileModal from './components/Editprofile/editprofile';
import DropRules from '../../components/Rules/rules';
import { PhotoRules } from '../../components/functions/rules';
import EditImgPerfil from './components/Editprofile/editimgperfil'


const ProfilePage = (props) => {
    const { user, setUser } = useContext(AuthContext)
    const [openeditmodal, setOpenEditModal] = useState(null)
    const [editimg, setEditImg] = useState(null)
    const [img, setImg] = useState(null)
    const [loading, setLoading] = useState(false)

    if (!user) {
        return <p>Carregando ...</p>
    }


    const AtualizarImg = (img) => {
        setLoading(true)
        const formData = new FormData()
        formData.append("img", img)
        formData.append("iduser", user.id)
        if (user.imagem == null) {
            api.post(`/auth/imgperfil/`, formData, { headers : { 'Content-Type' : 'multipart/form-data'}})
            .then((res) => {
                setUser({...user , imagem: res.data})
                setLoading(false)
                    })
            .catch((err) => {
                window.alert("Ocorreu um erro em sua solicitação")
                setLoading(false)
            })
            
        } else {
            api.patch(`/auth/imgperfil/${user.id}/`, formData, { headers : { 'Content-Type' : 'multipart/form-data'}})
            .then((res) => {
                setUser({...user, imagem : res.data})
                setLoading(false)
            })
            .catch((err) => {
                window.alert("Ocorreu um erro em sua solicitação")
                setLoading(false)
            })
        }
    }


    const DateFunction = (id) => {
        const nd = id.replace(/[^0-9]/, '')
        const nde = nd.substring(5, 9) 
        return nde
    } 

    return(
        <>
        <NavBar user={user}/>
        <div className='container-page-feed'>
            
            <div className='container-user'>
                <DadosUser data={user}/>
            </div>

            <div className='container-feedprofile'>
                <div className="profile">
                    <label className="inputimg" htmlFor="icon-button-file">
                        <IconButton component="span">
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <Avatar id="avatarphotocam" ><PhotoCameraIcon id="photocam" /></Avatar>
                                }
                                >
                                <Avatar id="avatar" alt={user.first_name} src={user.imagem? user.imagem.img : null} sx={{ width: 150, height: 150 }}></Avatar>
                            </Badge>
                            <DropRules text={PhotoRules()} block={true} id="icon-button-file" button={true} AtualizarImg={AtualizarImg}/>
                            <EditImgPerfil open={Boolean(img)} close={setImg} img={img} />
                        </IconButton>
                        
                    </label>
                   
                    <h1>{user.first_name}</h1>
                </div>
                <div className="profile-data">
                    {user.cargo && <p><WorkIcon /> Sou <b>{user.cargo}</b></p>}
                    {user.dateadmicao && <p><PsychologyIcon/> Trabalho nas Óticas Diniz desde <b>{DateFunction(user.dateadmicao)}</b></p>}
                    { user.escolaridade &&
                        user.escolaridade.map((user) => (
                            <p key={user.id}><SchoolIcon/> { user.status === 'C' ? 'Formado em ' : 'Cursando ' } <b>{user.descricao}</b></p>
                        ))
                    }
                    {user.cidade && <p><HouseIcon/> Moro em <b>{user.cidade}</b></p>}
                    {user.biografia && <><p><AssignmentIcon/> Sobre mim:</p><p><b>{user.biografia}</b></p></>}
                </div>
                <div className="ButtonsProfile">
                    <Button variant="contained" id="button-post" onClick={() => setOpenEditModal(true)} >Editar Perfil</Button>
                </div>
            </div>
            <div className='container-news'>
                <News/>
            </div>
        </div>
        {openeditmodal && <EditProfileModal user={user} setUser={setUser} openeditmodal={openeditmodal} setOpenEditModal={setOpenEditModal}/>}
        </>
                
    )
}

export default ProfilePage;