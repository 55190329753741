import React, {useState} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './criarusuario.css'
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { api } from '../../../../services/api';
import AlertText from '../../../../components/Alert/alert';



const schema = yup.object({
    first_name: yup.string().required("O Nome é obrigatório"),
    username: yup.string().required("O Usuário é obrigatório."),
    password: yup.string().min(6, "A senha deve conter no mínimo 6 caracteres").required("a Senha é obrigatória"),
    email: yup.string().email('Email não é válido').required("O E-mail é obrigatório"),
  }).required();


const ModalCreateUser = ({setOpenCreate, opencreate}) => {
    const { control , handleSubmit, watch, formState: { errors }} = useForm({
        resolver: yupResolver(schema)
      })
    const [alert, setAlert] = useState({ open: false, texto: '', tipoalert: 'warning'})
    const [block, setBlock] = useState(false)


    const CriarLogin = async (data) => {
        setBlock(true)
        await api.post('/auth/create/', data)
        .then((res) => {
            setAlert({ open: true, texto: "Parabéns !!! agora você faz parte do Veja Bem !", tipoalert: 'success'})
        })
        .catch((err) => {
            if (err.response.data.username[0]) {
                setAlert({ open: true, texto: err.response.data.username[0], tipoalert: 'error'})
            } else {
                setAlert({ open: true, texto: "Algo deu errado, por favor tente mais tarde !", tipoalert: 'error'})
            }
        })
        .finally((res) => setBlock(false))

    }


    return (
        <Modal
        open={opencreate}
        onClose={() => setOpenCreate(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box id='box-modal-create-user' sx={{ textAlign: 'center'}}>
                    <div className='close-modal-create'>
                        <IconButton id="iconclose" onClick={() => setOpenCreate(false)}><CloseIcon/></IconButton>
                    </div>
                    
                    <h1>Cadastre-se</h1>
                    <label>Para se cadastrar no novo Veja-Bem, preencha todos os dados abaixo e clique em criar !</label>
                    <form className='formulario'>
                        
                        <Controller
                            name="first_name"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                <TextField error={errors.first_name ? true : false} id="create-nome" label="Nome Completo" variant="outlined" fullWidth onChange={onChange} value={value}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                     ),
                                    }}/>
                                    </>
                                    )}
                                    />
                        {errors.first_name ? <span>{errors.first_name.message}</span> : null}
                        <br/>
                        <Controller
                            name="username"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField error={errors.username ? true : false} id="create-username" label="Usuário" variant="outlined" fullWidth onChange={onChange} value={value}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                        }}/>
                                
                                )}
                        />
                        {errors.username ? <span>{errors.username.message}</span> : null}
                        <br/>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField error={errors.password ? true : false} id="create-password" label="Senha" type='password' variant="outlined" fullWidth onChange={onChange} value={value}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <VpnKeyIcon />
                                    </InputAdornment>
                                ),
                                }}/>
                                
                                )}
                        />
                        {errors.password ? <span>{errors.password.message}</span> : null}
                        <br/>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField error={errors.email ? true : false} id="create-email" label="E-mail" type='email' variant="outlined" fullWidth onChange={onChange} value={value}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                                }}/>
                                
                                )}
                        />
                        {errors.email ? <span>{errors.email.message}</span> : null}
                        <br/>

                        <Button disabled={block} id="button-criar" variant="contained" onClick={handleSubmit(( data ) => CriarLogin(data))}>Criar</Button>
                    
                    </form>
                
                    <AlertText data={alert} close={() => setAlert({...alert, open: !alert.open })} />
                    
            </Box>

        </Modal>
    )
}

export default ModalCreateUser;