import React, { useEffect, useRef} from 'react'



const UIInfiniteScroll = ({ buscar }) => {
    const containerRef = useRef();

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1
        }
        const observer = new IntersectionObserver(([entry]) => {
            console.log(entry.isIntersecting)
            if (entry.isIntersecting) {
                buscar()
                observer.disconnect()
            }
        }, options);
        observer.observe(containerRef.current);
    }, [containerRef])

    return <div ref={containerRef}/>


}

export default UIInfiniteScroll;