import React, {useState} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { api } from '../../../../services/api';
import AlertText from '../../../../components/Alert/alert';



const schema = yup.object({
    email: yup.string().email('Email não é válido').required("O E-mail é obrigatório"),
  }).required();


const ResetPassword = ({setOpenCreate, opencreate}) => {
    const { control , handleSubmit, watch, formState: { errors }} = useForm({
        resolver: yupResolver(schema)
      })
    const [block, setBlock] = useState(false)
    const [alert, setAlert] = useState({ open: false, texto: '', tipoalert: 'warning'})

    const EnviarEmail = (data) => {
        setBlock(true)
        api.post(`/auth/reset_password/`, data)
        .then((res) => {
            setAlert({ open: true, texto: 'Foi enviado para o seu e-mail o passo a passo para resetar a senha !', tipoalert:'success'})
        })
        .catch((err) => {
            setAlert({ open: true, texto: 'Não foi encontrado nenhuma conta vinculado a esse email, favor contatar o T.I.', tipoalert:'error'})
        })
        .finally((fin) => {
            setBlock(false)
        })
    }


    return (
        <Modal
        open={opencreate}
        onClose={() => setOpenCreate(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box id='box-modal-create-user' sx={{ textAlign: 'center'}}>
                    <div className='close-modal-create'>
                        <IconButton id="iconclose" onClick={() => setOpenCreate(false)}><CloseIcon/></IconButton>
                    </div>
                    <AlertText data={alert} close={() => setAlert({...alert, open: !alert.open })} />
                    <h1>Resetar Senha</h1>
                    <label>Para resetar sua senha, apenas insira o e-mail cadastrado na sua conta, </label>
                    <br/>
                    <label>e enviaremos um link para esse e-mail para que seja criado a nova senha.</label>
                    <form className='formulario'>
                        
                        <Controller
                            name="email"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                <TextField error={errors.email ? true : false} id="create-nome" label="E-mail" variant="outlined" fullWidth onChange={onChange} value={value}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                     ),
                                    }}/>
                                    </>
                                    )}
                                    />
                        {errors.email ? <span>{errors.email.message}</span> : null}
                        <br/>

                        <Button disabled={block} id="button-criar" variant="contained" onClick={handleSubmit(( data ) => EnviarEmail(data))}>Criar</Button>
                    
                    </form>
                
                    
            </Box>

        </Modal>
    )
}

export default ResetPassword;