import React, { useContext } from 'react';
import DadosUser from '../index/components/dadosuser/dadosuser';
import News from '../index/components/news/news';
import NavBar from '../../components/NavBar/navbar';
import { AuthContext } from '../../contexts/auth';
import LoadingPage from '../../components/Loading/loading';
import { useAxios } from '../../hooks/useAxios';
import { Button } from '@mui/material'
import IMGPROCESS from '../../assets/processos.png'

const Processos = () => {
    const { user } = useContext(AuthContext)
    const { data } = useAxios('/news/processos/')

    if (!user) {
        return <LoadingPage/>
    }

    

    return(
        <>
        <NavBar user={user}/>
        <div className='container-page-feed'>
            
            <div className='container-user'>
                <DadosUser data={user}/>
            </div>

            <div className='container-feedprofile'>
                <div className='content-tittle-newspaper'>
                    <h2>Processos</h2>
                    <img src={IMGPROCESS} style={{ width: 200, maxWidth: '100%', borderRadius: 15 }}/>
                </div>
                <br/>
                { data ? data.results.map((res) => (
                    <Button variant='text' href={res.content} key={res.id} sx={{ width: '100%', color: 'black', borderRadius: 10}}>
                        <div className="content-id-newspaper">
                            <div>
                            <h2>{res.tittle}</h2>
                            <p>{res.describe}</p>
                            </div>
                            <hr style={{ margin: 10}}/>
                            <div>
                                <p><b>Atualizado em</b></p>
                                <p>{res.created_at}</p>
                            </div>
                        </div>
                    </Button>

                )) : null }


            </div>
            <div className='container-news'>
                <News/>
            </div>
        </div>
        </>
                
    )
}

export default Processos;