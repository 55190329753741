


export const FeedRules = () => {
   
   return (
    <>
    <h3>Um ambiente para você mostrar as colegas suas experiências profissionais !</h3>
    <ul>
     <li>Suas vendas.</li>
     <li>A beleza de sua loja.</li>
     <li>Sua alegria em ser Diniz.</li>
    </ul>
    </>
   )
    
}

export const PhotoRules = () => {

   return (
      <>
      <h3>Deixe aqui uma foto bem bonita para seus colegas !</h3>
      <ul>
       <li>Foto em ambiente profissional.</li>
       <li>uniforme Diniz maravilhoso !</li>
       <li>Seu sorriso pra alegrar o dia de alguém !</li>
      </ul>
      </>
   )
}