import React, { useEffect } from 'react';
import AppRoutes from "./AppRoutes"
import OneSignal from 'react-onesignal';



function App() {

  useEffect(() => {
      OneSignal.init({ appId: 'cdb3d37e-595f-4338-b657-0f0a065c0e22'})
      OneSignal.showSlidedownPrompt();
  })

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
