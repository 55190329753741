import React, {useState, useContext} from 'react';
import './login.css'
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IMGRed from '../../assets/logo-n.png'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AlertText from '../../components/Alert/alert';
import ModalCreateUser from './components/criarusuario/criarusuario';
import { AuthContext } from '../../contexts/auth'
import { Navigate } from 'react-router-dom';
import ResetPassword from './components/resetpassword/resetpassword';


const schema = yup.object({
    username: yup.string().required("O Usuário é obrigatório."),
    password: yup.string().min(6, "A senha deve conter no mínimo 6 caracteres").required("a Senha é obrigatória"),
  }).required();


const Login = () => {
    const { control , handleSubmit, watch, formState: { errors }} = useForm({
        resolver: yupResolver(schema)
      })
    const { authenticated, login} = useContext(AuthContext);
    const [ opencreate, setOpenCreate ] = useState(false)
    const [ resetpass, setResetPass] = useState(false)
    const [alert, setAlert] = useState({ open: false, texto: '', tipoalert: 'warning'})
    const [ blockbutton, setBlockButton] = useState(false)

    if (authenticated === true) {
        return <Navigate to="/" />
    }



    const entrar = async (data) => {
        setBlockButton(true)
        const x = await login(data)
        if (x) {
            if (x.non_field_errors) {
                setAlert({open: true, texto: x.non_field_errors[0], tipoalert: 'error'})
            }

        }
        setBlockButton(false)
    };


    return (
        <div className='container-login'>
            <div className='caixa-login'>
                <img src={IMGRed}/>
                <Box id="box-login" autoComplete="off">
                <AlertText data={alert} close={() => setAlert({...alert, open: !alert.open })} />
                    <form className="formulario">
                            <Controller
                        
                        name="username"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField id="login-usuario" label="Usuário" variant="outlined" fullWidth onChange={onChange} value={value} autoComplete="off"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}/>                                                                
                                    )}
                                    />
                                {errors.username ? <span>{errors.username.message}</span> : null}
                            <br/>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField id="login-senha" label="Senha" variant="outlined" fullWidth onChange={onChange} value={value} type="password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKeyIcon />
                                            </InputAdornment>
                                        ),
                                        }}/>                                                                
                                )}
                                />
                                {errors.password ? <span>{errors.password.message}</span> : null}

                            <div className="buttons-login">
                            <Button disabled={blockbutton} variant="contained" onClick={handleSubmit((data) => entrar(data))}>Entrar</Button>
                            <Button variant="contained" onClick={() => setOpenCreate(true)}>Cadastre-se</Button>
                            {opencreate && <ModalCreateUser setOpenCreate={setOpenCreate} opencreate={opencreate}/>}   
                            </div>
                            <div className="esquecisenha-login">
                            <Button variant="text" id="buttonesqueceusenha" onClick={() => setResetPass(!resetpass)}>Esqueceu sua senha ?</Button>
                            { resetpass && <ResetPassword setOpenCreate={setResetPass} opencreate={resetpass} />}
                            </div>
                        </form>
                </Box>
            </div>
        </div>
    )
}

export default Login;