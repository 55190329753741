import axios from "axios";
import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import {api, createSession } from "../services/api";




export const AuthContext = createContext();


export const AuthProvicer = ({children}) => {
    const navigate = useNavigate();
    const [activo, setActivo] = useState(null)
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [config, setConfig] = useState(null)

    useEffect(() => {
        const recoveredUser = localStorage.getItem('iduser');
        const token = localStorage.getItem('token');

        if(recoveredUser && token) {
            api.defaults.headers.Authorization = `token ${token}`
            api.get(`/auth/create/${recoveredUser}/`)
            .then(user => {
                setUser(user.data)
            });
            api.get('/auth/config/')
            .then((res) => {
                setConfig(res.data.results)
      
            });
            api.patch(`/auth/create/${recoveredUser}/`, {last_login: new Date().toISOString()})
        }
        setActivo(recoveredUser)
        setLoading(false);
    }, []);


    const login = async (data) => {
        await api.post('/auth/', data)
        .then((res) => {
            const loggedUser = res.data.nome
            const token = res.data.token
            const iduser = res.data.iduser

            localStorage.setItem("nome", JSON.stringify(loggedUser));
            localStorage.setItem("token", token);
            localStorage.setItem('iduser', iduser);

            api.defaults.headers.Authorization = `token ${token}`

            api.get(`/auth/create/${iduser}/`)
            .then(user => {
                setUser(user.data)
            })

            api.get('/auth/config/')
            .then((res) => {
                setConfig(res.data.results)
            })

            api.patch(`/auth/create/${iduser}/`, {last_login: new Date().toISOString()})
            setActivo(loggedUser)
            setError(null)
            navigate("/")
        })
        .catch((err) => {
            const seterror = err.response.data
            setError(seterror)
        })
        return error
    };
    
    const logout = () => {
        setConfig(null)
        setUser(null);
        setActivo(null)
        setError(null)
        localStorage.removeItem("nome")
        localStorage.removeItem("token")
        localStorage.removeItem("iduser")
        api.defaults.headers.Authorization = null;
        navigate("/login")
    };

    return (
        <AuthContext.Provider value={{ authenticated: !!activo, activo, loading, login, logout, config, user, setUser, setConfig, error, setError }}>{children}</AuthContext.Provider>
    )
}